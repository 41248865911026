/* Global Styling */
body {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Modern font family */
  background-color: #f4f7fa; /* Soft light background color */
  color: #2c3e50; /* Dark text for better contrast and readability */
  line-height: 1.6;
}

* {
  box-sizing: border-box;
}

/* Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #34495e; /* Darker shade for navbar */
  padding: 15px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  transition: all 0.3s ease-in-out;
}

/* Navbar Links Styling */
.nav-links {
  display: flex;
  gap: 20px;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  /* Make nav-links visible on larger screens */
  display: flex;
}

/* Navbar Links (on mobile screen) */
.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase; /* Uppercase links for a refined look */
  margin: 0 20px;
  padding: 5px 0;
  transition: color 0.3s ease, border-bottom 0.3s ease; /* Smooth transition */
}

.nav-links a:hover {
  color: #1abc9c; /* Accent color for hover */
  border-bottom: 2px solid #1abc9c; /* Stylish underline on hover */
}

/* Hamburger Menu (Visible on mobile) */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 25px;
  cursor: pointer;
}

.hamburger div {
  width: 100%;
  height: 4px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

/* For small screens: Hide the navbar links initially */
@media (max-width: 768px) {
  /* Hide nav-links by default on small screens */
  .nav-links {
    display: none; /* Hide links initially on mobile */
    width: 100%;
    text-align: center;
    flex-direction: column; /* Stack items vertically */
  }

  /* Show hamburger menu on mobile */
  .hamburger {
    display: flex;
  }

  /* Display the navbar links when the navbar has 'active' class */
  .navbar.active .nav-links {
    display: flex;
  }

  /* Animate hamburger into a cross when active */
  .navbar.active .hamburger div:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 8px;
  }

  .navbar.active .hamburger div:nth-child(2) {
    opacity: 0;
  }

  .navbar.active .hamburger div:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    top: -8px;
  }
}
