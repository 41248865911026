/* Global Styling */
body {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #000; /* Black background for elegance */
  color: #fff; /* White text for contrast */
}

/* Home Container Styling */
.home-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0 50px;
  overflow: hidden;
}

.text-section {
  max-width: 50%;
}

.main-heading {
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  margin: 0 0 20px;
  border-left: 5px solid #fff; /* Vertical line for a minimalistic touch */
  padding-left: 15px;
  animation: fadeInDown 1.5s ease-out;
}

.subtext {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 30px;
  color: #ccc; /* Soft white for secondary text */
  line-height: 1.6;
  animation: fadeInUp 1.5s ease-out;
}

/* Social Icons Styling */
.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-icon {
  font-size: 1.5rem;
  color: #fff; /* White icons */
  text-decoration: none;
  transition: transform 0.3s, color 0.3s;
}

.social-icon:hover {
  color: #ccc; /* Lighter hover effect */
  transform: scale(1.2); /* Slight zoom-in effect */
}

/* Image Styling */
.image-section {
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
  border: 5px solid #fff; /* White border for sharp contrast */
  animation: zoomIn 1.5s ease-out;
}

/* Footer Styling */
.footer {
  text-align: center;
  background-color: #000;
  color: #ccc; /* Softer white */
  padding: 15px 0;
  border-top: 1px solid #333; /* Thin border for separation */
}

.footer p {
  margin: 0;
  font-size: 0.9rem;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
    text-align: center;
    padding: 80px;
  }

  .text-section {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .image-section {
    max-width: 100%;
  }

  .profile-image {
    max-width: 90%;
  }

  .main-heading {
    font-size: 3rem;
  }

  .subtext {
    font-size: 1rem;
  }

  .social-icons {
    justify-content: center;
    gap: 15px;
  }
}
/* Image Section */
.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures no part of the image goes outside the container */
}

.profile-image {
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  object-fit: cover; /* Ensures image covers the container without stretching */
  object-position: top center; /* Focuses the image on the top (head) */
  transform: scale(
    1.2
  ); /* Zoom in to eliminate the extra space above and below */
  transition: transform 0.5s ease-out; /* Smooth transition */
}

/* Optional: Zoom-in effect on hover */
.profile-image:hover {
  transform: scale(1.25); /* Further zoom-in on hover */
}
